<template>
  <div class="container">
    <h1>One Click Design</h1>
    <h2>Meet One Click Design: Your YouTube Channel's New Best Friend</h2>
    <p>
      <strong
        >Ever wished for a personal AI buddy who's an expert on your YouTube
        channel,</strong
      >
      always ready to chat with your audience and help you create amazing
      content?
    </p>
    <h3>Just One Click Away</h3>
    <p>
      With a single click, you can create an AI expert trained on your entire
      YouTube history. This super-smart AI is available 24/7 to answer
      questions, keep your viewers engaged, and even have some fun banter with
      your followers. It's like having your very own sidekick!
    </p>

    <h3>Dashboard Delights</h3>
    <p>
      Log in to your dashboard, and be amazed by data-driven video ideas based
      on what your viewers are discussing with your AI. Need a script or b-roll?
      No problem – just click, and let One Click Design generate everything for
      you. No more busy work or guesswork, just more time to create awesome
      content.
    </p>

    <h3>Unleash Your AI's Potential</h3>
    <p>
      Your AI expert is more than just a conversation starter – it's a versatile
      tool to grow your channel. From suggesting videos and products to booking
      paid calls with followers, your AI sidekick is here to help you connect
      with your audience like never before.
    </p>

    <p>
      Get ready to transform your YouTube channel with One Click Design. Free
      yourself from the tedious tasks and focus on what you love – creating
      high-quality content backed by user data. Let's make some YouTube magic
      together!
    </p>
  </div>
</template>
<style>
.container {
  background-color: #2b2b2b;
  color: #fff;
  padding: 20px;
  font-size: 18px;
  line-height: 1.5;
  font-family: Arial, sans-serif;
}

h1 {
  font-size: 36px;
  margin-bottom: 10px;
}

h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

strong {
  font-weight: bold;
}

p {
  margin-bottom: 20px;
}
</style>
<script>
export default {
  data() {
    return {};
  },
};
</script>