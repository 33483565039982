<template>
  <div class="privacy-policy">
    <h1>OneClick.designs Privacy Policy</h1>
    <p>At OneClick.designs, your privacy is our priority. This Privacy Policy outlines the way we collect, utilize, and disclose information when you interact with our website and services.</p>

        <h2>Information Collection and Usage</h2>

        <p>We do not store any data, except for the channel name and ID, which are collected from your YouTube channel exclusively to verify your channel ownership. This data is solely used for creating a user profile and is never shared with third parties.</p>

        <h2>Information Sharing</h2>

        <p>We do not share any information with third parties. The channel name and ID we gather are strictly used to confirm your channel ownership.</p>

        <h2>Data Security</h2>

        <p>We are committed to ensuring the security of your information and have implemented measures to safeguard against unauthorized access, usage, or disclosure. Nevertheless, no system is entirely secure, and we cannot guarantee the absolute security of your information.</p>

        <h2>Third-Party Websites</h2>

        <p>Our website may feature links to third-party websites. We hold no responsibility for the privacy practices or content of these websites. We advise you to review the privacy policies of these websites before submitting any personal information.</p>

        <h2>Children's Privacy</h2>

        <p>Our website and services are not intended for children under 13 years old. We do not knowingly collect personal information from children under 13. If you suspect that we have collected personal information from a child under 13, please contact us immediately, and we will promptly remove the information.</p>

        <h2>Privacy Policy Updates</h2>

        <p>We may occasionally update this Privacy Policy. We will inform you of any significant changes by posting the updated Privacy Policy on our website. Your continued use of our website and services after the revised Privacy Policy has been posted signifies your acceptance of the modifications.</p>

        <h2>Contact Us</h2>

        <p>If you have any inquiries or concerns regarding our Privacy Policy, please reach out to us at <a href="mailto:privacy@oneclick.designs">privacy@oneclick.designs</a>.</p>
</div>
</template>
<script>
export default {
  name: 'PrivacyPolicy',
}
</script>

<style scoped>
  .privacy-policy {
    background-color: black;
    color: white;
    padding: 20px;
    text-align: right; /* Add this line to align all text to the right */
  }
  h1 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center; /* Reset text alignment for heading */
  }
  h2 {
    font-size: 24px;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 10px;
    text-align: center; /* Reset text alignment for subheading */
  }
  p {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 20px;
    text-align: left; /* Reset text alignment for paragraph */
  }
  a {
    color: white;
    text-decoration: underline;
  }
</style>